import React from "react";
import BG1 from "./graphics/bg1.jpg";
import BG2 from "./graphics/BG2.jpg";
import BG3 from "./graphics/BG3.jpg";
import BG4 from "./graphics/BG4.jpg";
import "./styles/Background.css";
import LOGO from "./graphics/AMGAS-full-logo.png";
import Map from "./Components/Map";
function Background() {
  return (
    <>
      <img
        src={LOGO}
        width={"40%"}
        height={"60%"}
        alt="LOGO"
        className="logo"
      />

      <div className="opacity-cover">
        <div id="captioned-gallery">
          <figure className="slider">
            <figure>
              <img src={BG1} alt={"altern"} />
            </figure>
            <figure>
              <img src={BG2} alt={"altern"} />
            </figure>
            <figure>
              <img src={BG1} alt={"altern"} />
            </figure>
            <figure>
              <img src={BG3} alt={"altern"} />
            </figure>
            <figure>
              <img src={BG4} alt={"altern"} />
            </figure>
          </figure>
        </div>
      </div>
      <div className="map-cont">
        <Map />
      </div>
    </>
  );
}

export default Background;
