import React, { useEffect } from "react";
import "../styles/Stock.css";
import axios from "axios";
import loader from "../graphics/loading.gif";

const { useState } = React;

function Stock() {
  let use: boolean = true;
  const [price, setPrice] = useState("0");

  useEffect(() => {
    let options: any;
    options = {
      method: "GET",
      url: "https://yh-finance.p.rapidapi.com/stock/v2/get-summary",
      params: { symbol: "IFNY", region: "US" },
      headers: {
        "x-rapidapi-host": "yh-finance.p.rapidapi.com",
        "x-rapidapi-key": "da485371b3msh53f94ee43ebff25p139e22jsn8fe318412c42",
      },
    };
    axios
      .request(options)
      .then(function (response) {
        setPrice(response.data.price.regularMarketPrice.fmt.toString());
      })
      .catch(function (error) {
        console.error("Error getting price: ", error);
      });
  }, [use]);

  return (
    <>
      {price === "0" ? (
        <div>
          <img src={loader} alt="loading" className={"loader"} />
        </div>
      ) : (
        <div>IFNY: {price}</div>
      )}
    </>
  );
}

export default Stock;
