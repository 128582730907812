import React from "react";
import "../styles/Welcome.css";
import { Fade } from "react-awesome-reveal";

function Welcome() {
  return (
    <section>
      <Fade cascade>
        <div className="content">
          <h2>Helium</h2>
          <h2>Helium</h2>
          <h2>Helium</h2>
          <h2>Helium</h2>
        </div>
        <h3 className="tagline">There is no substitute.</h3>
      </Fade>
    </section>
  );
}

export default Welcome;
