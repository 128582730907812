import React from "react";
import "../styles/Investing.css";
import { Slide, Fade } from "react-awesome-reveal";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import News from "../Components/News";
import StockChart from "../Components/StockChart";
function Investing() {
  return (
    <>
      <div className={"banner-invest"}>
        <h1 className={"banner-title"}>Investing</h1>
      </div>
      <h1 className={"text-center"}>
        <b>CHART</b>
      </h1>
      <StockChart />
      <Fade>
        <Slide direction={"up"} triggerOnce>
          <h1 className={"text-center margin-top bot-1"}>
            <b>Financial Information</b>
          </h1>
          <Container className={"files-container"}>
            <Row className={"file-row-annual"}>
              <Col className={"divider-col"}>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221021353/formdefa14a.htm"
                      }
                    >
                      Annual Proxy Statement
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221007173/form10-k.htm"
                      }
                    >
                      2020 Annual 10-K Report
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={"text-center"}>
              <h2>Form 10-Q</h2>
            </div>
            <Row className={"file-row-10k"}>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221011205/form10-q.htm"
                      }
                    >
                      March 31, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/ix?doc=/Archives/edgar/data/822746/000149315221019305/form10-q.htm"
                      }
                    >
                      June 30, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/ix?doc=/Archives/edgar/data/822746/000149315221028025/form10-q.htm"
                      }
                    >
                      September 30, 2021
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={"text-center"}>
              <h2>Form 8-K</h2>
            </div>
            <Row className={"file-row-8k"}>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/ix?doc=/Archives/edgar/data/822746/000149315221027961/form8-k.htm"
                      }
                    >
                      November 12, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/ix?doc=/Archives/edgar/data/822746/000149315221027379/form8-k.htm"
                      }
                    >
                      November 5, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/ix?doc=/Archives/edgar/data/0000822746/000149315221025501/form8-k.htm"
                      }
                    >
                      October 15, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/ix?doc=/Archives/edgar/data/0000822746/000149315221019799/form8-k.htm"
                      }
                    >
                      August 13, 2021
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className={"file-row-8k-bot"}>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221016691/0001493152-21-016691-index.htm"
                      }
                    >
                      July 13, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221014397/0001493152-21-014397-index.htm"
                      }
                    >
                      June 15, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221008091/form8-k.htm"
                      }
                    >
                      April 6, 2021
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/ix?doc=/Archives/edgar/data/0000822746/000149315221019799/form8-k.htm"
                      }
                    >
                      August 13, 2021
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={"text-center"}>
              <h2>Forms</h2>
            </div>
            <Row className={"file-row-other"}>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221009427/form8-ka.htm"
                      }
                    >
                      April 22, 2021 Form 8-K/A
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221031301/form8-a12ga.htm"
                      }
                    >
                      December 13, 2021 Form 8-12G/A
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221031299/0001493152-21-031299-index.htm"
                      }
                    >
                      December 13, 2021 Form 8-12G3
                    </a>
                  </div>
                </div>
              </Col>
              <Col>
                <div>
                  <FontAwesomeIcon
                    icon={faFile}
                    size="6x"
                    className={"file-icon"}
                  />
                  <div>
                    <a
                      href={
                        "https://www.sec.gov/Archives/edgar/data/822746/000149315221017433/0001493152-21-017433-index.htm"
                      }
                    >
                      July 21, 2021 Form S-1
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
            <div className={"text-center"}>
              <h2>Information Statement to Shareholders</h2>
            </div>
            <div className={"text-center info-state"}>
              <FontAwesomeIcon
                icon={faFile}
                size="6x"
                className={"file-icon"}
              />
              <div>
                <a
                  href={
                    "https://www.sec.gov/Archives/edgar/data/0000822746/000149315221027346/formdef14c.htm"
                  }
                >
                  November 5, 2021
                </a>
              </div>
            </div>
          </Container>
          <h1 className={"text-center release"}>
            <b>Recent Press Releases</b>
          </h1>
          <News />
        </Slide>
      </Fade>
    </>
  );
}

export default Investing;
