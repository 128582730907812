import React from "react";
import "./styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap/dist/react-bootstrap.min";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Helium from "./Pages/Helium";
import Investing from "./Pages/Investing";
import NavBar from "./Components/NavBar";
import Background from "./Background";
import Footer from "./Components/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
function App() {
  const isComputer = useMediaQuery({ query: "(min-width: 1024px)" });

  return (
    <>
      <Container fluid className="cont">
        <Row>
          {isComputer && (
            <Col className="column col-bg">
              <Background />
            </Col>
          )}
          <Col xl={7} className="column">
            <NavBar />
            <Routes>
              <Route
                path="/"
                element={
                  <div className={"body-cont"}>
                    <Home />
                  </div>
                }
              />
              <Route
                path="/projects"
                element={
                  <div className={"body-cont"}>
                    <Helium />
                  </div>
                }
              />
              <Route
                path="/about"
                element={
                  <div className={"body-cont"}>
                    <About />
                  </div>
                }
              />
              <Route
                path="/invest"
                element={
                  <div className={"body-cont"}>
                    <Investing />
                  </div>
                }
              />
            </Routes>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default App;
