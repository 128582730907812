import React from "react";
import "../styles/Footer.css";
import { Navbar, Nav } from "react-bootstrap";
function Footer() {
  return (
    <>
      <Navbar className={"foot bg-primary navbar-custom-green-text"}>
        <Nav className="footy text-center">
          <Nav className={"link"}>
            American Noble Gas Inc. "All Rights Reserved." Developed by Robert
            Truesdale III
          </Nav>
        </Nav>
      </Navbar>
    </>
  );
}

export default Footer;
