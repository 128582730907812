import React from "react";
import "../styles/About.css";
import { Card, Col, Container, Row } from "react-bootstrap";
import ROSS from "../graphics/Stan-Ross.jpeg";
import JOHN from "../graphics/john.png";
import LEROY from "../graphics/Leroy 2.jpg";
import DANIEL from "../graphics/Daniel 2.jpg";
import { Slide, Fade } from "react-awesome-reveal";
import Map from "../Components/Map";
import { useMediaQuery } from "react-responsive";

function About() {
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <>
      <Fade triggerOnce>
        <div className={"banner-about"}>
          <h1 className={"banner-title"}>About Us</h1>
        </div>
      </Fade>
      {isMobile && (
        <div className={"about-title"}>
          <h1>WHERE WE ARE</h1>
        </div>
      )}
      {isMobile && (
        <div>
          <Container className={"about-map-cont"}>
            <Map />
          </Container>
        </div>
      )}
      <Fade triggerOnce className={"padding"}>
        <div className={"about-title"}>
          <h1>WE ARE</h1>
          <ul>
            <Slide direction={"right"} triggerOnce cascade>
              <li>Explorers for helium.</li>
              <li>
                Participants in a deal with options for up to 50 horizontal or
                vertical wells in the Hugoton Field, Kansas, USA, a known Helium
                province.
              </li>
            </Slide>
          </ul>
          <h1>WE HAVE</h1>
          <ul>
            <Slide direction={"right"} triggerOnce cascade>
              <li>
                Established helium infrastructure with high net-back values
              </li>
              <li>Control of helium price destiny</li>
            </Slide>
          </ul>
        </div>
        <div>
          <h3 className={"text-center"}>Experienced Management Team</h3>
        </div>
        <Container className={"team-cont"}>
          <Row className={"staff-row"} md={2} xs={1}>
            <Col>
              <Card className={"staff-card"}>
                <Card.Text>
                  <img alt="user" src={ROSS} className={"staff-img"} />
                </Card.Text>
                <div className={"staff-inner-cont"}>
                  <div className={"staff-name"}>Stanton E. Ross</div>
                  <div className={"staff-title"}>
                    Chief Executive Officer, President & Chairman of the Board
                  </div>
                  <div className={"staff-desc"}>
                    From March 1992 to June 2005, Mr. Ross was Infinity’s
                    Chairman and President and served as an officer and director
                    of each of its subsidiaries. He resigned all of these
                    positions with Infinity in June 2005, except Chairman, but
                    was reappointed as Infinity’s President in October 2006. Mr.
                    Ross has served as Chairman, President and Chief Executive
                    Officer of Digital Ally, Inc. (“Digital”) since September
                    2005. Digital is a publicly held company whose common stock
                    is traded on the Nasdaq Capital Market under the symbol
                    DGLY. From 1991 until March 1992, he founded and served as
                    President of Midwest Financial, a financial services
                    corporation involved in mergers, acquisitions and financing
                    for corporations in the Midwest. From 1990 to 1991, Mr. Ross
                    was employed by Duggan Securities, Inc., an investment
                    banking firm in Overland Park, Kansas, where he primarily
                    worked in corporate finance. From 1989 to 1990, he was
                    employed by Stifel, Nicolaus & Co., a member of the New York
                    Stock Exchange, where he was an investment executive. From
                    1987 to 1989, Mr. Ross was self-employed as a business
                    consultant. From 1985 to 1987, Mr. Ross was President and
                    founder of Kansas Microwave, Inc., which developed a radar
                    detector product. From 1981 to 1985, he was employed by
                    Birdview Satellite Communications, Inc., which manufactured
                    and marketed home satellite television systems, initially as
                    a salesman and later as National Sales Manager. Mr. Ross
                    allocates his time between Digital and the Company as he
                    deems necessary to discharge his fiduciary duties to each of
                    them. Because of the Company’s reduced level of activity and
                    the needs of Digital, he has devoted most of his time to
                    Digital and the balance to the Company during the last year
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className={"staff-card"}>
                <Card.Text>
                  <img alt="user" src={DANIEL} className={"staff-img"} />
                </Card.Text>
                <div className={"staff-inner-cont"}>
                  <div className={"staff-name"}>Daniel F. Hutchins</div>
                  <div className={"staff-title"}>
                    Chief Financial Officer, Treasurer & Board Member
                  </div>
                  <div className={"staff-desc"}>
                    Mr. Hutchins was elected to serve as a Director of Infinity
                    and was also appointed to serve as Chief Financial Officer
                    of Infinity effective as of August 13, 2007. Mr. Hutchins
                    was elected as a Director of Digital Ally, Inc. in December
                    2007, serves as Chairman of its Audit Committee and is its
                    financial expert. He is also a member of Digital’s
                    Nominating and Governance Committee. Mr. Hutchins, a
                    Certified Public Accountant, was a Principal with the
                    accounting firm of Hutchins & Haake, LLC until his
                    retirement on July 1, 2021. He was previously a member of
                    the Advisory Board of Digital Ally. Mr. Hutchins has served
                    as an instructor for the Becker CPA exam with the Keller
                    Graduate School of Management and has over 18 years of
                    teaching experience preparing CPA candidates for the CPA
                    exam. He has over 30 years of public accounting experience,
                    including five years with Deloitte & Touche, LLP. He holds
                    no other public directorships and has not held any others
                    during the previous five years. He has served on the boards
                    of various non-profit groups and is a member of the American
                    Institute of Certified Public Accountants. Mr. Hutchins
                    earned his Bachelor of Business Administration degree in
                    Accounting at Washburn University in Topeka, Kansas.
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className={"staff-card"}>
                <Card.Text>
                  <img alt="user" src={LEROY} className={"staff-img"} />
                </Card.Text>
                <div className={"staff-inner-cont"}>
                  <div className={"staff-name"}>Leroy C. Richie</div>
                  <div className={"staff-title"}>
                    Lead Outside Director on the Board
                  </div>
                  <div className={"staff-desc"}>
                    Mr. Richie has been a director of Infinity since June 1,
                    1999. Since 2005, Mr. Richie has served as the lead outside
                    director of Digital Ally, Inc. and currently serves as a
                    member of Digital’s Audit Committee and is the Chairman of
                    its Nominating and Governance and Compensation Committees.
                    Additionally, until 2017, Mr. Richie served as a member of
                    the boards of directors of Columbia Mutual Funds, (or mutual
                    fund companies acquired by or merged with Columbia Mutual
                    Funds), a family of investment companies managed by
                    Ameriprise Financial, Inc. From 2004 to 2015, he was of
                    counsel to the Detroit law firm of Lewis & Munday, P.C. He
                    holds no other public directorships and has not held any
                    others during the previous five years, except for OGE Energy
                    Corp. (2007-2014) and Kerr-McGee Corporation (1998-2005).
                    Mr. Richie served as Vice-Chairman of the Board of Trustees
                    and Chairman of the Compensation Committee for the Henry
                    Ford Health System, in Detroit until retirement in December
                    2020. Mr. Richie was formerly Vice President of Chrysler
                    Corporation and General Counsel for automotive legal
                    affairs, where he directed all legal affairs for its
                    automotive operations from 1986 until his retirement in
                    1997. Before joining Chrysler, he was an associate with the
                    New York law firm of White & Case (1973-1978), and served as
                    director of the New York office of the Federal Trade
                    Commission (1978-1983). Mr. Richie received a B.A. from City
                    College of New York, where he was valedictorian, and a J.D.
                    from the New York University School of Law, where he was
                    awarded an Arthur Garfield Hays Civil Liberties Fellowship.
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
        <div>
          <h3 className={"text-center"}>Experienced Board of Advisors</h3>
        </div>
        <Container className={"advisor-cont"}>
          <Row xs={1} md={2}>
            <Col>
              <Card className={"advisor-card"}>
                <div className={"advisor-inner-cont"}>
                  <div className={"staff-name"}>Paul Mendell</div>
                  <div className={"staff-title"}>Advisory Board Member</div>
                  <div className={"staff-desc"}>
                    Early shale pioneer. Creative generator of exploration
                    concepts for Helium.
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className={"advisor-card"}>
                <div className={"advisor-inner-cont"}>
                  <div className={"staff-name"}>Tom Harrison</div>
                  <div className={"staff-title"}>Advisory Board Member</div>
                  <div className={"staff-desc"}>
                    32 years in the helium industry for Praxair, a major helium
                    player.
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className={"advisor-card"}>
                <div className={"advisor-inner-cont"}>
                  <div className={"staff-name"}>Jon Gluyas</div>
                  <div className={"staff-title"}>Advisory Board Member</div>
                  <div className={"staff-desc"}>
                    A leading authority on carbon capture and geology of helium.
                    Chair of GeoEnergy, Durham University.
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card className={"advisor-card"}>
                <div className={"advisor-inner-cont"}>
                  <div className={"staff-name"}>Christopher Ballentine</div>
                  <div className={"staff-title"}>Advisory Board Member</div>
                  <div className={"staff-desc"}>
                    A leading authority on noble gases, and Chairman of
                    Geochemistry at Oxford University
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fade>
    </>
  );
}
export default About;
