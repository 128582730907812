import React from "react";
import "../styles/NavBar.css";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Stock from "./Stock";
import { useMediaQuery } from "react-responsive";
import logo from "../graphics/AMGAS-words.png";
function NavBar() {
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <>
      <Navbar
        className={"navbar-custom-green-text navy"}
        expand="lg"
        sticky="top"
        collapseOnSelect
      >
        {isMobile && (
          <Navbar.Brand as={Link} to={"/"}>
            <img
              src={logo}
              height="90"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
        )}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="">
            <Nav.Link eventKey="1" as={Link} to={"/"} className={"link"}>
              Home
            </Nav.Link>
            <Nav.Link
              eventKey="2"
              as={Link}
              to={"/projects"}
              className={"link link-right"}
            >
              Projects
            </Nav.Link>
          </Nav>
          {!isMobile && (
            <Nav className="nav-logo">
              <Nav.Link eventKey="3" as={Link} to={"/"}></Nav.Link>
            </Nav>
          )}
          <Nav className="justify-content-end">
            <Nav.Link
              eventKey="4"
              as={Link}
              to={"/about"}
              className={"link link-left"}
            >
              About
            </Nav.Link>
            <Nav.Link eventKey="5" as={Link} to={"/invest"} className={"link"}>
              Investors
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className={"ticker text-end"}>
        <Stock />
      </div>
      <div className={"value-banner"}>
        Individual Character &emsp;
        <span style={{ color: "red" }}>&#9632;</span>
        &emsp;Imagination &emsp;
        <span style={{ color: "red" }}>&#9632;</span>
        &emsp;Execution &emsp;
        <span style={{ color: "red" }}>&#9632;</span>
        &emsp;Relationships Matter
      </div>
    </>
  );
}

export default NavBar;
