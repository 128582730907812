import React from "react";
import "../styles/Home.css";
import Welcome from "../Components/Welcome";
import { Faded } from "baby-i-am-faded";
import { Slide, Fade } from "react-awesome-reveal";
import { Card, Col, Container, Row } from "react-bootstrap";
function Home() {
  return (
    <>
      <Faded whenInView triggerOnce cascade>
        <Welcome />
      </Faded>
      <Slide direction={"up"} triggerOnce>
        <div className={"home-pic-container bottom-right-shadow"}>
          <Fade delay={1500}>
            <Card className={"home-card"}>
              <div>
                <h1 className={"home-intro"}>
                  Explorer of helium-rich, natural gas
                </h1>
              </div>
              <div>
                <h5 className={"intro-p"}>
                  Helium is an essential element, with several important and
                  high value applications.
                </h5>
              </div>
            </Card>
          </Fade>
          <Fade delay={2500}>
            <Card className={"home-prod-card"}>
              <div>
                <h3>For many applications, there remains no substitute.</h3>
              </div>
              <Container>
                <Row className={"justify-content-md-center"}>
                  <Col md="4" className={"prod-col"}>
                    <Card className={"aero-pic-cont"}></Card>
                    <h5>Aerospace</h5>
                  </Col>
                  <Col md="4" className={"prod-col"}>
                    <Card className={"semi-pic-cont"}></Card>
                    <h5>Semi-Conductors</h5>
                  </Col>
                  <Col md="4" className={"prod-col"}>
                    <Card className={"mri-pic-cont"}></Card>
                    <h5>MRI</h5>
                  </Col>
                </Row>
              </Container>
            </Card>
          </Fade>
        </div>
      </Slide>
    </>
  );
}

export default Home;
