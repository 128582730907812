import React, { Component } from "react";
import "../styles/Map.css";
import { Card } from "react-bootstrap";
import { TelephoneFill, EnvelopeFill } from "react-bootstrap-icons";
declare global {
  interface Window {
    H: any;
  }
}
interface IProps {
  debug?: boolean;
}
interface IState {
  lat: number;
  lng: number;
  zoom: number;
}
class Map extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      lat: 38.92791,
      lng: -94.76731,
      zoom: 15,
    };
  }
  componentDidMount() {
    let H = (window as any).H;
    let platform = new H.service.Platform({
      apikey: "_cFa_635TDcHVJOcr4_fycwm7F6CEV6ikv3RB6hzShA",
    });
    let defaultLayers = platform.createDefaultLayers();
    let map = new H.Map(
      document.getElementById("map"),
      defaultLayers.vector.normal.map,
      {
        center: { lat: this.state.lat, lng: this.state.lng },
        zoom: this.state.zoom,
        pixelRatio: window.devicePixelRatio || 1,
      }
    );
    const ui = H.ui.UI.createDefault(map, defaultLayers);
    let homeMarker = new H.map.Marker({
      lat: this.state.lat,
      lng: this.state.lng,
    });
    homeMarker.setData(
      `
        <h3 style="text-align:center; width: 10em;">American Noble Gas</h3>
        <div style="text-align:center;">15612 College Blvd. Lenexa KS 66219</div>
      `
    );
    homeMarker.addEventListener(
      "tap",
      (event: any) => {
        const bubble = new H.ui.InfoBubble(
          { lat: this.state.lat, lng: this.state.lng },
          {
            content: event.target.getData(),
          }
        );
        ui.addBubble(bubble);
      },
      false
    );
    map.addObject(homeMarker);
    map.getViewModel().setLookAtData({ tilt: 20, heading: 0 });
    window.addEventListener("resize", () => map.getViewPort().resize());
    new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
    H.ui.UI.createDefault(map, defaultLayers);
  }
  render() {
    return (
      <div className="mapWrapper">
        <div className="map" id="map" />
        <Card className="map-foot-card">
          <div className="map-foot-title">
            <h3>American Noble Gas Inc.</h3>
            <div>15612 College Blvd. Lenexa KS 66219</div>
            <div>
              <TelephoneFill color={"#22419c"} /> (913) 948-9512
            </div>
            <div>
              <EnvelopeFill color={"#dc1300"} /> investing@amnoblegas.com
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
export default Map;
