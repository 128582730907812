import React, { useEffect } from "react";
import "../styles/StockChart.css";
import axios from "axios";
import loader from "../graphics/loading.gif";
import { Chart } from "react-google-charts";
const { useState } = React;
function StockChart() {
  let use: boolean = true;
  const [chartDrawn, getChart] = useState(() => createChart(0));
  const [marketCap, getCap] = useState(() => "updating...");
  useEffect(() => {
    let optionsGET: any;
    optionsGET = {
      method: "GET",
      url: "https://yh-finance.p.rapidapi.com/stock/v2/get-analysis",
      params: { symbol: "IFNY", region: "US" },
      headers: {
        "X-RapidAPI-Host": "yh-finance.p.rapidapi.com",
        "X-RapidAPI-Key": "da485371b3msh53f94ee43ebff25p139e22jsn8fe318412c42",
      },
    };
    axios
      .request(optionsGET)
      .then(function (response) {
        getCap(response.data.price.marketCap.fmt);
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [use]);

  useEffect(() => {
    let prices: any[] = [];
    let timestamp: any;
    let high: any;
    let optionsGET: any;
    optionsGET = {
      method: "GET",
      url: "https://yh-finance.p.rapidapi.com/stock/v2/get-chart",
      params: { interval: "1d", symbol: "IFNY", range: "6mo", region: "US" },
      headers: {
        "x-rapidapi-host": "yh-finance.p.rapidapi.com",
        "x-rapidapi-key": "da485371b3msh53f94ee43ebff25p139e22jsn8fe318412c42",
      },
    };

    axios
      .request(optionsGET)
      .then(function (response) {
        timestamp = response.data.chart.result[0].timestamp;
        high = response.data.chart.result[0].indicators.quote[0].high;
        assignPrices();
      })
      .catch(function (error) {
        console.error(error);
      });
    function assignPrices() {
      for (let i = 0; i < timestamp.length; i++) {
        if (!timestamp[i] || timestamp[i] === null) timestamp[i] = 0;
        if (!high[i] || timestamp[i] === null) high[i] = 0.3;
        const date = new Date(timestamp[i] * 1000);
        timestamp[i] = date.toLocaleDateString("en-US");
      }
      for (let i = 0; i < timestamp.length; i++) {
        prices.push([timestamp[i], high[i]]);
      }
      getChart(createChart(prices));
    }
  }, [use]);

  function createChart(prices: any) {
    if (prices === 0) return "loading";
    let data = [["Time", "Price"]];
    for (let i = 0; i < prices.length; i++) {
      data.push(prices[i]);
    }
    const options = {
      chart: {
        title: "Stock Price for IFNY",
        subtitle: "Daily highs (past 6 months)",
      },
    };
    return (
      <>
        <Chart
          chartType="Line"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      </>
    );
  }

  return (
    <>
      {chartDrawn === "loading" ? (
        <div>
          <img src={loader} alt="loading" className={"load"} />
        </div>
      ) : (
        <>
          <div className={"chart"}>{chartDrawn}</div>

          <div className={"chart"}>Market Cap: {marketCap}</div>
        </>
      )}
    </>
  );
}

export default StockChart;
