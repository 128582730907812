import React from "react";
import "../styles/Helium.css";
import { Col, Nav, Tab, Row, Fade, Container } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
function Helium() {
  return (
    <>
      <div className={"banner"}>
        <h1 className={"banner-title"}>Our Projects</h1>
      </div>
      <Tab.Container transition={Fade} id="left-tabs" defaultActiveKey="first">
        <Row>
          <Col sm={2}>
            <Nav variant="tabs" className="flex-column">
              <Nav.Item>
                <Nav.Link className={"tabby"} eventKey="first">
                  Helium
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={"tabby"} eventKey="second">
                  Hugoton
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={"tabby"} eventKey="third">
                  Project Helium Shale
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Slide triggerOnce direction={"up"}>
                  <Container className={"prod-cont"}>
                    <Row className={"title-row"}>
                      <div className={"title"}>
                        <h1>HELIUM</h1>
                      </div>
                    </Row>
                  </Container>
                  <div className={"prod-bod"}>
                    <h1>ABOUT</h1>
                    <p>
                      &emsp; Helium is a precious, non-renewable resource that
                      provides an array off applications, some of which that are
                      without substitute. Helium comes from radioactive decay of
                      uranium and thorium. One kilogram of uranium generates a
                      few liters of helium, over millions of years.
                    </p>
                    <p>
                      &emsp; Global demand is estimated to be around 6 billion
                      cubic feet (Bcf) per annum. The applications for helium
                      are currently increasing including areas in aerospace, and
                      in the healthcare industry – for MRI scanners, especially
                      in developing countries. Superconductor manufacturing is
                      another application and a rapidly growing area is for
                      pressure purging purposes in rocketry (Space X and NASA
                      programs).
                    </p>
                    <h1>THE VALUE OF HELIUM</h1>
                    <p>
                      &emsp; Helium is an opaque industry and pricing is subject
                      to political and subjective market forces. For example,
                      Russian helium and helium from Qatar, is generally a major
                      supply source.
                    </p>
                    <p>
                      &emsp; Though Helium has historically been controlled by
                      the US Federal Government via its helium storage
                      infrastructure, private sales of helium today have reached
                      the highest levels ever seen.
                    </p>
                    <p>
                      Prices quoted, subject to volume and timing may range from
                      $300 to $500 per 1,000 cubic feet, or even higher
                    </p>
                    <h1>EXPLORATION AND CHALLENGES</h1>
                    <p>
                      &emsp; The Falsehood that ‘bigger is better’ – many helium
                      explorers chase high composition of helium, like the old
                      timers in the gold mining industry chased a vein rich in
                      gold! Today, most of the world’s gold is produced from
                      extremely low concentrations of the mineral - Parts per
                      million, of every ton of ore, mined.
                    </p>
                    <p>
                      &emsp; The overwhelming economic consideration for helium
                      is not its naturally occurring concentration. It is the
                      cost of new infrastructure, for its recovery, and
                      proximity to markets. For example, a higher helium content
                      without infrastructure introduces a monumental ‘chicken
                      and egg’ scenario where the justification of
                      infrastructure capital, rests upon the proven threshold
                      volume of the gas discovered.
                    </p>
                    <p>
                      &emsp; We believe the world’s future helium supply will be
                      sourced from reservoirs near existing infrastructure, and
                      from sources that are low to moderate concentrations of
                      helium, as a by-product of natural gas development.
                    </p>
                  </div>
                </Slide>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Slide triggerOnce direction={"up"}>
                  <Container className={"prod-cont"}>
                    <Row className={"title-row"}>
                      <div className={"title"}>
                        <h1>Hugoton</h1>
                      </div>
                    </Row>
                  </Container>
                  <div className={"prod-bod"}>
                    <h1>BACKGROUND/INFO</h1>
                    <p>
                      &emsp; Hugoton is the largest conventional gas field in
                      North America, possessing one of the largest helium supply
                      sources in the world. Hugoton Field offers existing
                      infrastructure representing billions of dollars worth of
                      replacement value. Our project is a farmout with major
                      undisclosed Hugoton Operator.
                    </p>
                    <div className={"map-pic"}></div>
                    <h1>HUGOTON HIGHLIGHTS</h1>
                    <ul>
                      <li>
                        Rights to Drill up to 50 wells, Vertical or Horizontal
                      </li>
                      <li>
                        Rights to utilize helium infrastructure, offering high
                        net-back values for helium.
                      </li>
                      <li>
                        Rights to brine minerals across two counties in Kansas.
                      </li>
                    </ul>
                  </div>
                </Slide>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Slide triggerOnce direction={"up"}>
                  <Container className={"prod-cont"}>
                    <Row className={"title-row"}>
                      <div className={"title"}>
                        <h1>Project Helium Shale</h1>
                      </div>
                    </Row>
                  </Container>
                  <div className={"prod-bod"}>
                    <h1>BACKGROUND/INFO</h1>
                    <p>
                      &emsp; Using modern shale technology and large hydraulic
                      frac treatments, Project Helium Shale is targeting over
                      100 feet of shale and tight carbonate rock in the field.
                    </p>
                    <div className={"frack-pic"}></div>
                    <h1>Why hasn't this been done before?</h1>
                    <p>
                      &emsp; Shale in the Hugoton does not meet the typical
                      requirements of the current thinking in the US Shale
                      Industry; the shale does not have much organic matter, it
                      is not thermally mature, and it does not have high
                      pressure. While the character and conditions of the
                      Hugoton shale (and tighter carbonate rocks), do not meet
                      the classic shale signatures, we believe gas may exist in
                      these units. Evidence that these rocks are bleeding gas
                      into the more porous main reservoirs may be revealing
                      itself during the past few years, based on the fact that
                      the Kansas portion of the field remains productive, at
                      ultra-low pressure.
                    </p>
                    <p>
                      &emsp; Shale and other types of rock are capable of slow
                      release of gas through desorption. Desorption is difficult
                      to detect from a conventional reservoir until very
                      low-pressure conditions exist. One clue to the presence of
                      adsorbed gas would be a departure from the pressure Vs.
                      volume curve.
                    </p>
                    <p>
                      &emsp; The sheer volume of gas being produced annually
                      from the field over parts of the reservoir that have
                      extremely low pressure may suggest that shale and tight
                      rocks need to be specific targets for completions
                      therefore releasing and accelerating the production
                      potential. We believe optimizing completions, including
                      stage fracking the shale, and tight members of the pay
                      package, may hold great potential.
                    </p>
                  </div>
                </Slide>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}

export default Helium;
