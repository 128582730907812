import React, { useEffect } from "react";
import loader from "../graphics/loading.gif";
import axios from "axios";
import { Container, Col, Row } from "react-bootstrap";
import "../styles/News.css";

const { useState } = React;

function News() {
  const [news, getNews] = useState("loading");
  const [storyList, getStories] = useState([]);

  useEffect(() => {
    let stories: any = [];
    let options: any;
    options = {
      method: "POST",
      url: "https://yh-finance.p.rapidapi.com/news/v2/list",
      params: { region: "US", snippetCount: "100", s: "IFNY" },
      headers: {
        "x-rapidapi-host": "yh-finance.p.rapidapi.com",
        "x-rapidapi-key": "da485371b3msh53f94ee43ebff25p139e22jsn8fe318412c42",
      },
    };
    axios
      .request(options)
      .then(function (response) {
        getNews("success");
        streamer(response.data.data.main.stream);
      })
      .catch(function (error) {
        getNews("failure");
        return console.error(error);
      });
    function streamer(stream: any) {
      if (!stream) return;
      for (let i = 0; i < stream.length; i++) {
        if (stream[i].content.thumbnail === null)
          stream[i].content.thumbnail = 0;
        stream[i].content.pubDate = new Date(stream[i].content.pubDate);
        stories.push([
          stream[i].content.contentType.toString(),
          stream[i].content.hasVideo,
          stream[i].content.provider.displayName,
          stream[i].content.title,
          new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          }).format(stream[i].content.pubDate),
          stream[i].content.thumbnail,
        ]);
      }
      // contentType, hasVideo, provider.displayName, title, pubDate, thumbnail
      getStories(stories);
    }
  }, []);

  return (
    <>
      {news === "loading" ? (
        <div>
          <img src={loader} alt="loading" className={"load"} />
        </div>
      ) : (
        <Container className={"story-cont"}>
          {storyList.map(function (story: any, index) {
            return (
              <Row key={index} className={"story-row"}>
                <Col>
                  {story[5] === 0 ? (
                    <h2 style={{ color: "#22419c" }}>{story[0]}</h2>
                  ) : (
                    <img
                      src={story[5].resolutions[0].url}
                      alt={"story"}
                      className={"story-photo"}
                    />
                  )}
                </Col>
                <Col xs={10}>
                  <Row className={"story-title"}>{story[3]}</Row>
                  <Row className={"story-other"}>
                    <Col>{story[2]}</Col>
                    <Col xs={6}> </Col>
                    <Col>{story[4]}</Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Container>
      )}
    </>
  );
}
export default News;
